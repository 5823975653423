import { AjaxStore, Combo } from '@bryntum/calendar';
import { Bus } from './Bus';

export const busStore = new AjaxStore({
    httpMethods: {
        create: 'POST',
        read: 'GET',
        update: 'PUT',
        delete: 'DELETE',
    },
    useRestfulMethods: true,
    modelClass: Bus,
    readUrl: '/api/bus',
    autoLoad: false,
    sorters: [
        {
            field: 'licenseplate',
            ascending: true,
        },
    ],
});

export class BusSelector extends Combo {
    static get type() {
        return 'busSelector';
    }

    static get configurable() {
        return {
            store: busStore,
            displayField: 'licenseplate',
        };
    }
}

BusSelector.initClass();