
import { Model } from '@bryntum/calendar';

export class Bus extends Model {
    static get fields() {
        return [
            { name: 'id', type: 'int' },
            { name: 'licenseplate', type: 'string' },
        ];
    }
}