import { AjaxStore, Combo } from '@bryntum/calendar';
import { EmployeeModel } from './EmployeeModel';
import { ConductorType } from '../../@types/employee';

interface VezenyloStoreInterface {
    [key: string]: AjaxStore;
}

export const vezenyloStore: VezenyloStoreInterface = {};

Object.values(ConductorType).forEach((conductorName) => {
    vezenyloStore[conductorName] = new AjaxStore({
        httpMethods: {
            create: 'POST',
            read: 'GET',
            update: 'PUT',
            delete: 'DELETE',
        },
        useRestfulMethods: true,
        modelClass: EmployeeModel,
        readUrl: `/api/employee?conductor=${conductorName}&jobTitle=autóbuszvezető`,
        autoLoad: false,
        sorters: [
            {
                field: 'name',
                ascending: true,
            },
        ],
    });
});

export const buszrentEmployeeStore = new AjaxStore({
    httpMethods: {
        create: 'POST',
        read: 'GET',
        update: 'PUT',
        delete: 'DELETE',
    },
    useRestfulMethods: true,
    modelClass: EmployeeModel,
    readUrl: `/api/employee?jobTitle=!autóbuszvezető`,
    autoLoad: false,
    sorters: [
        {
            field: 'name',
            ascending: true,
        },
    ],
});

export class EmployeeSelector extends Combo {

    static get type() {
        return 'EmployeeSelector';
    }

    static get configurable() {
        return {
            displayField: 'name',
        };
    }
}
EmployeeSelector.initClass();
