import React from 'react';
import { Box, Typography } from '@mui/material';
import { Layout } from '../Layout';


const NotFoundPage = () => {
    return <Layout>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography variant={'h1'}>404 |</Typography>
            <Typography variant={'h3'} pt={4} pl={3}>Nothing is here</Typography>
        </Box>
    </Layout>;
};
export { NotFoundPage };