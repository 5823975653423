export const ResourceID = {
    SZERVIZ_KOZPONT: 1,
    SZERVIZ_KULSOS: 2,
    SZERVIZ_ELOJEGYZES: 3,
    SZERVIZ_FELADATOK: 4,
    ELERHETO_SOFOR_DEL: 5,
    FOGLALT_SOFOR_DEL: 6,
    ELERHETO_SOFOR_ESZAK: 7,
    FOGLALT_SOFOR_ESZAK: 8,
    ELERHETO_SOFOR_SZEGED: 9,
    FOGLALT_SOFOR_SZEGED: 10,
    SZABADSAGIGENY: 11,
    SZABADSAG: 12,
};

export type EventData = {
    id: number | string;
    name: string;
    startDate: string | Date;
    endDate: string | Date;
    allDay: boolean;
    resourceId: number | string;
    recurrenceCombo: string;
    recurrenceRule: string;
    busId?: number;
    employeeId?: string;
    exceptionDates?: string[];
    availability?: string;
    version?: number;
};

export type ResourcesData = {
    id: number;
    name: string;
    eventColor: string;
    entityType: number;
};
